/*
    Методы для работы с API основных справочников
*/


import api from '@/helpers/apiGet'
export default {
    // TODO: Запросы для кафедр, факультетов и т.д.
    async getDepartments () {
        let response = await api.get('/api/kafs')
        return  response.data.listKafs
    },
    async getFaculties () {
        let response = await api.get('/api/faculties')
        return  response
    },
    async getSpecialties () {
        let response = await api.get('/api/Spets')
        return  response.data.listSpets
    },
    async getGroups (facultyID, specialtyID, groupID, year) {
        let response = await api.get('/api/GroupsList', { facultyID: facultyID, specialtyID: specialtyID, groupID: groupID, year: year })
        return  response.data.listGroups
    },
    async getStudents (groupID) {
        let response = await api.get('/api/Students', { groupID: groupID })
        return  response.data.listStudents
    },
    async getGroupInfo (groupID) {
        let response = await api.get('/api/UserInfo/GroupInfo', { groupID: groupID })
        return  response.data
    },
    async getTeachingLoadOfTeachers (facultyID, year) {
        let response = await api.get('/api/Nagr/Groups', { facultyID: facultyID,  year: year})
        return  response.data
    },

    async getTeachingLoadOfTeachersFIO (kafID, year) {
        let response = await api.get('/api/Nagr/Teachers', { kafID: kafID,  year: year})
        return  response.data
    },

    async getLoadDisciplines (groupId, selyear, teacherId, kafId) {
        let response = await api.get('/api/Nagr/LoadDisciplines', { groupId:groupId, selyear:selyear, teacherId:teacherId, kafId:kafId})
        return  response.data
    },
}
