<!--

TODO Реализовать border-radiusom отделение месяцев
TODO Переход нажатием на дисциплину на ведомость
TODO Переход нажатием на препода на препода

-->
<template>
    <div class="ma-auto mt-3 px-3">
        <v-layout style="max-width: 100%;">
            <v-card-title class="pl-3" v-if="dataGraph !== null">
                <span v-if="dataGraph.generalHeader !== undefined">
                    График учебного процесса {{dataGraph.generalHeader.course}} курса
                </span>
            </v-card-title>
            <v-spacer></v-spacer>
        </v-layout>
        <v-card class="pa-3">
            <v-layout>
                <v-chip class="mr-3" @click="$router.back()" small> <v-icon left>mdi-chevron-left</v-icon> Назад</v-chip>
                <span v-if="dataGraph === null">
          Не удалось загрузить график учебного процесса :(
        </span>
            </v-layout>
        </v-card>

        <v-row v-if="dataGraph !== null" class="mt-0">
            <!--    Левая колонка титулки -->
            <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <v-card class="mt-3">
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content><b>Факультет</b></v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{dataGraph.generalHeader.facultyNameShort}}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content><b>Группа</b></v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{dataGraph.generalHeader.groupName}}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

            </v-col>
            <!--    Средняя колонка титулки -->
            <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <v-card class="mt-lg-3 mt-md-3 mt-sm-0 mt-xs-0">
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content><b>Учебный год</b></v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{dataGraph.generalHeader.year}}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content><b>Семестр</b></v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{dataGraph.generalHeader.semester}}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

            </v-col>
            <!--    Левая колонка титулки -->
            <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <v-card class="mt-lg-3 mt-md-3 mt-sm-0 mt-xs-0">
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content><b>Курс</b></v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{dataGraph.generalHeader.course}}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content><b>Учебный план</b></v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{dataGraph.generalHeader.plan}}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

            </v-col>
        </v-row>

<!--        Таблица с графиком-->
        <v-card v-if="dataGraph !== null" class="mt-6">
            <div class="table-wrapper">
            <table v-if="dataGraph.graphs !== undefined" class="table-graph">
                <thead>
                <tr>
                    <td colspan="3">Дисциплина</td>
                    <td v-for="(month, index) in dataGraph.weekMonthHeader.months"
                        :key="month"
                        :colspan="dataGraph.weekMonthHeader.monthRowspans[index]">
                        {{month}}
                    </td>
                    <td colspan="2">Контроль</td>
                    <td rowspan="4" class="td-header-leftborder">Преподаватели[часы]</td>
                </tr>
                <tr class="tr-first-number-of-week">
                    <td rowspan="3">Блок</td>
                    <td rowspan="3">Ауд. час.</td>
                    <td rowspan="3">Кафедра</td>
                    <td v-for="(firstDayOfWeek, indexFDOW) in dataGraph.weekMonthHeader.firstDayOfWeekRows"
                        :key="indexFDOW"
                        class="td-first-number-of-week"
                        :class="{'cell-current-number-week':dataGraph.weekMonthHeader.weekNumbers[indexFDOW] === dataGraph.generalHeader.currentWeek}"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                        v-bind="attrs"
                                        v-on="on"
                                >{{firstDayOfWeek}}</span>
                            </template>
                            <span>День начала недели</span>
                        </v-tooltip>
                    </td>
                    <td rowspan="3">Вид</td>
                    <td rowspan="3">Дата, ауд., пары</td>
                </tr>
                <tr class="tr-last-number-of-week">
                    <td v-for="(lastDayOfWeek, indexLDOW) in dataGraph.weekMonthHeader.lastDayOfWeekRows"
                        :key="indexLDOW"
                        class="td-last-number-of-week"
                        :class="{'cell-current-number-week':dataGraph.weekMonthHeader.weekNumbers[indexLDOW] === dataGraph.generalHeader.currentWeek}"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                        v-bind="attrs"
                                        v-on="on"
                                >{{lastDayOfWeek}}</span>
                            </template>
                            <span>День конца недели</span>
                        </v-tooltip>

                    </td>
                </tr>
                <tr class="tr-number-week">
                    <td v-for="(weekNumber, weekNumberIndex) in dataGraph.weekMonthHeader.weekNumbers"
                        :key="weekNumberIndex"
                        class="td-number-week"
                        :class="{'cell-current-number-week':weekNumber === dataGraph.generalHeader.currentWeek}"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                        v-bind="attrs"
                                        v-on="on"
                                >{{weekNumber}}</span>
                            </template>
                            <span v-if="weekNumber === dataGraph.generalHeader.currentWeek">Текущая учебная наделя</span>
                            <span v-else>Номер недели в учебном году</span>
                        </v-tooltip>

                    </td>
                </tr>
                </thead>
                <tbody>
                <template v-for="(graph, indexGraph) in dataGraph.graphs">
                    <tr v-bind:key="indexGraph"
                        class="tr-discipline"
                        :class="{'tr-discipline-selected': !graph.active}"
                    >
                        <td colspan="3">{{graph.discipline}}</td>
                        <td v-for="(cell, cellIndex) in getListFromStringGraph(graph.graph)"
                            rowspan="2"
                            class="graph-cell"
                            :key="cellIndex+'cellIndex'"
                            :class="{'td-elective-period':isElectiveCell(cell, graph.active)}"
                        >
                            <span v-if="cell === 'КТ'">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                                v-bind="attrs"
                                                v-on="on"
                                                class="graph-cell-control-point"
                                        >КТ</span>
                                    </template>
                                    Контрольная точка на {{dataGraph.weekMonthHeader.weekNumbers[cellIndex]}} неделе
                                </v-tooltip>
                            </span>
                        </td>
                        <td rowspan="2" class="graph-cell">
                            {{getShortNameVedType(graph.vedType, indexGraph)}}
                        </td>
                        <td rowspan="2">
                            {{graph.dateControl}}<br>{{graph.roomNumberAndPositionLesson}}
                        </td>
                        <td rowspan="2">
                            <span v-for="validator in graph.studentValidators" :key="validator.graphicsPrepID+'graphicsPrepID'">
                                {{validator.prepod}} - {{validator.type}}[{{validator.hours}}]
                                <span v-if="validator.typeControl.length > 0">({{validator.typeControl}})</span>
                                <br>
                            </span>
                            <span v-if="graph.active === 0">Не выбрана</span>
                        </td>

                    </tr>
                    <tr v-bind:key="graph.discipline"
                        :class="{'tr-discipline-selected': !graph.active}"
                    >
                        <td>{{graph.block}}</td>
                        <td>{{graph.hours}}</td>
                        <td>{{graph.shortCathedra}}</td>
                    </tr>
                </template>
                </tbody>
            </table>
            </div>
        </v-card>
        <v-card v-else class="my-6 pa-6">
            Не удалось загрузить график или график отсутствует.
        </v-card>
<!--        Таблица годовая -->
        <year-graph-table-as-plany></year-graph-table-as-plany>
    </div>
</template>

<script>
import graphs from "@/helpers/api/graphs";
import YearGraphTableAsPlany from "../../components/YearGraphTableAsPlany";

export default {
    name: "GraphOpen",
    data(){
        return{
            dataGraph: null
        }
    },
    components:{YearGraphTableAsPlany},
    mounted() {
        this.loadDataGraph()
    },
    computed: {
        groupID(){
            return this.$route.params.groupID
        },
        semester(){
            return this.$route.params.semester
        },
        currentMonths(){
            let monthsName = []
            if (this.dataGraph.generalHeader.semester === "Осенний"){
                monthsName = this.constAutumnMonths
            }
            if (this.dataGraph.generalHeader.semester === "Весенний"){
                monthsName = this.constSpringMonths
            }
            return monthsName
        }
    },
    methods: {
        loadDataGraph(){
            console.log("Semester: ",this.semester);
            graphs.getGraph(this.groupID, this.semester).then((data)=>{
                //console.log("Data group", data.data);
                this.dataGraph = data.data;
            })
        },
        getListFromStringGraph(grahp){
            if (this.dataGraph.generalHeader.semester === "Осенний"){
                return grahp.split(";").slice(0, this.dataGraph.weekMonthHeader.weekNumbers.length)
            }
            if (this.dataGraph.generalHeader.semester === "Весенний"){
                return grahp.split(";").slice(0, this.dataGraph.weekMonthHeader.weekNumbers.length)
            }
        },
        getShortNameVedType(name, indexGraph){
            // Вытаскивает короткое имя с преподов для обозначения зачета с оценкой
            let shortname = ""
            if (name === 'Зачет') shortname = "За"
            if (name === 'Экзамен') shortname = "Эк"
            this.dataGraph.graphs[indexGraph].studentValidators.forEach(function (elem) {
                if (elem.typeControl.length > 0){
                    shortname = elem.typeControl
                }
            })
            return shortname
        },
        isElectiveCell(cellElement, isActiveDiscipline) {
            if (isActiveDiscipline === 0) return false
            return (cellElement === "КТ") || (cellElement === "")
        }
    }
}
</script>

<style scoped>
    .table-wrapper{
        width: 100%;
        display:block;
        overflow-x: auto;
    }
    .table-graph{
        border-collapse: collapse;
        width: 100%;
        border-radius: 5px;
        font-size: 0.9rem;
    }
    .table-graph td {
        padding: 5px;
        text-align: center;
    }
    .table-graph tr:not(:last-child) {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
    /*Шапка номеров недель*/
    .table-graph .tr-first-number-of-week{
        border-bottom: none !important;
    }
    .table-graph .tr-last-number-of-week{
        border-bottom: none !important;
    }
    .table-graph .tr-number-week{
        border-bottom: none !important;
    }
    .table-graph .td-header-leftborder{
        border-left: thin solid rgba(0, 0, 0, 0.12);
    }
    .table-graph .td-first-number-of-week{
        border-bottom: none;
        cursor: help;
    }
    .table-graph .td-last-number-of-week{
        border-bottom: none;
        cursor: help;
    }
    .table-graph .td-number-week{
        border-bottom: none;
        background-color: #f6f6f6;
        cursor: help;
    }
    .table-graph .td-number-week:first-child{
        background-color: #f6f6f6;
        border-top-left-radius: 5px;
        /*border-bottom-left-radius: 5px;*/
    }
    .table-graph .td-number-week:last-child{
        background-color: #f6f6f6;
        border-top-right-radius: 5px;
        /*border-bottom-right-radius: 5px;*/
    }

    .cell-current-number-week{
        background-color: rgba(7, 7, 7, 0.15) !important;
        border-left: 1px solid rgba(7, 7, 7, 0.05) ;
        border-right: 1px solid rgba(7, 7, 7, 0.05) ;
    }
    /*Первая дисциплина в таблице графиков*/
    .table-graph .tr-discipline:first-child{
        border-top: thin solid rgba(7, 7, 7, 0.15);
        /*border-top: thin solid rgba(7, 7, 7, 0.12);*/
        /*box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;*/
    }
    .table-graph .tr-discipline-selected{
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }
    .table-graph tbody tr:nth-child(even){
        background-color: #f6f6f6;
    }
    .table-graph thead{
        font-size: 0.8rem;
        font-weight: bold;
        /*color: rgba(0, 0, 0, 0.6);*/
    }
    .table-graph tbody{
        font-size: 0.8rem;
    }

    .graph-cell{
        border-left: thin solid rgba(7, 7, 7, 0.15);
    }
    .graph-cell-control-point{
        cursor: help;
    }
    .td-elective-period{
        background-color: #e2f5d8;
    }
    /*.table-graph tr:last-child td:first-child {*/
    /*    border: 5px solid orange;*/
    /*    border-bottom-left-radius: 10px;*/
    /*}*/

    /*.table-graph tr:last-child td:last-child {*/
    /*    border: 5px solid green;*/
    /*    border-bottom-right-radius: 10px;*/
    /*}*/
</style>