/*
    Методы для работы с API учебными графиками
*/
import api from '@/helpers/apiGet'
export default {
    async getGraph(groupID, semester){
        /*
        *  groupID  - код группы по которой строятся графики сдачи экзамена
        *  semester - семестр за который строится график. 0 - осенний, 1 - весенний
        * */
        return await api.get('/api/graphs/graph', {groupID: groupID, semester: semester})
    },
    async getGraphCalendarGraphsTwoView(groupID) {
        /*
        * Получает таблицу графиков в двух видах. Как в планах и стандартные.
        *  year  - год за который сформируется шапка
        *  groupID  - код группы по которой строятся графики сдачи экзаменов
        * */
        return await api.get('api/graphs/GraphCalendarGraphsTwoView', {groupID: groupID})
    },
}