<template>
  <div style="max-width: 1000px;" class="ma-auto mt-3">
    <vue-headful :title="'Учебные графики'"/>
    <v-layout style="max-width: 100%;">
      <v-btn class="align-self-center" small outlined style="text-transform: inherit;padding: 2px 8px;font-size: 14px;" @click="$router.go(-1)" color="#004992">
        <v-icon left>mdi-arrow-left-circle-outline</v-icon>
        Назад
      </v-btn>
      <v-card-title class="pl-3">
        Учебные графики
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
          solo
          class="mb-3 search-field"
          clearable
      ></v-text-field>
    </v-layout>
    <v-slide-x-transition>
      <div v-if="!loading">
        <v-layout  class="mb-3">
          <v-card style="width: 100%;" class="pa-3">
            <year-picker v-model="year"></year-picker>
          </v-card>
        </v-layout>

        <v-card>
          <v-data-table :page.sync="page" :items-per-page="15" :loading="loading" :search="search" :items="groups" :headers="groupsHeaders">
            <template v-slot:item.groupName="{ item }">
              <a style="white-space: nowrap;" :href='`#/Graphs/${item.groupID}`'>
                <v-icon left>mdi-link</v-icon>
                {{item.groupName}}
              </a>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-slide-x-transition>
  </div>
</template>

<script>
import directory from "@/helpers/api/directory";
import common from "@/helpers/common";

import YearPicker from "@/components/YearPicker";
export default {
  name: "GraphsList",
  components: {YearPicker},
  data () {
    return {
      search: null,
      year: common.getCurrentYear(),
      page: null,
      loading: false,
      groups: [],
    }
  },
  computed: {
    groupsHeaders () {
      return [
        {text: 'Группа', value: 'groupName'},
        {text: 'Курс', value: 'course'},
        {text: 'Факультет', value: 'faculty'},
        {text: 'Специальность', value: 'speciality'},
      ]
    },
  },
  mounted() {
    this.loadGroups()
  },
  methods: {
    async loadGroups () {
      this.loading = true
      this.groups =  await directory.getGroups(null, null, null, this.year)
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>