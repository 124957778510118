<template>
    <v-card v-if="data !== null" class="mt-5">
        <v-radio-group class="pt-5" v-model="radioTypeGraph">
            <div class="align-self-center">
                <v-radio value="asPlany" label="Графики календарный вид (как в учебном плане)"></v-radio>
                <v-radio value="asNormal" :label="'Графики календарный вид (на '+data.currentYear+' год)'"></v-radio>
            </div>
        </v-radio-group>
<!--        Вид как в планах-->
    <div v-if="radioTypeGraph === 'asPlany'" class="table-wrapper">
    <table class="table-graph" >
        <thead>
        <tr>
            <td v-for="(month, index) in data.planyHeaderGraphs.months"
                :key="month"
                :colspan="data.planyHeaderGraphs.monthRowspans[index]">
                {{month}}
            </td>
        </tr>
        </thead>
        <tr>
            <td  v-for="(dayWeek1, index) in data.planyHeaderGraphs.dayWeeks[0]"
                 :key="index">{{dayWeek1}}</td>
        </tr>
        <tr>
            <td  v-for="(dayWeek2, index) in data.planyHeaderGraphs.dayWeeks[1]"
                 :key="index">{{dayWeek2}}</td>
        </tr>
        <tr>
            <td  v-for="(dayWeek3, index) in data.planyHeaderGraphs.dayWeeks[2]"
                 :key="index">{{dayWeek3}}</td>
        </tr>
        <tr>
            <td  v-for="(dayWeek4, index) in data.planyHeaderGraphs.dayWeeks[3]"
                 :key="index">{{dayWeek4}}</td>
        </tr>
        <tr>
            <td  v-for="(dayWeek5, index) in data.planyHeaderGraphs.dayWeeks[4]"
                 :key="index">{{dayWeek5}}</td>
        </tr>
        <tr>
            <td  v-for="(dayWeek6, index) in data.planyHeaderGraphs.dayWeeks[5]"
                 :key="index">{{dayWeek6}}</td>
        </tr>
        <tr>
            <td  v-for="(dayWeek7, index) in data.planyHeaderGraphs.dayWeeks[6]"
                 :key="index">{{dayWeek7}}</td>
        </tr>
        <tr class="tr-number-week">
            <td  v-for="(week, index) in data.planyHeaderGraphs.weekNumbers"
                 :key="index"
                class="td-number-week"
            >{{week}}</td>
        </tr>
<!-- Нижняя часть таблицы (заполнение экзаменов, каникул и т.д.)-->
        <tr v-for="(weekData, index2) in data.planyDataGraphs">
            <td v-for="(status, index) in weekData"
                :key="index"
            >{{status}}</td>
        </tr>
    </table>
<!--        Календарный вид (на 2021-2022 учебный год) (он же стандартный) -->
    </div>
    <div v-if="radioTypeGraph === 'asNormal'" class="table-wrapper">
    <table class="table-graph">
<!--        Шапка таблицы -->
        <thead>
        <tr>
            <td>Мес.</td>
            <td v-for="(month, index) in data.planyHeaderGraphs.months"
                :key="month"
                :colspan="data.planyHeaderGraphs.monthRowspans[index]">
                {{month}}
            </td>
        </tr>
        </thead>
        <tr>
            <td class="hilight-name-day">Пн.</td>
            <td  v-for="(dayWeek1, index) in data.standartHeaderGraphs.dayWeeks[0]"
                 :key="index">{{dayWeek1}}</td>
        </tr>
        <tr>
            <td class="hilight-name-day">Вт.</td>
            <td  v-for="(dayWeek2, index) in data.standartHeaderGraphs.dayWeeks[1]"
                 :key="index">{{dayWeek2}}</td>
        </tr>
        <tr>
            <td class="hilight-name-day">Ср.</td>
            <td  v-for="(dayWeek3, index) in data.standartHeaderGraphs.dayWeeks[2]"
                 :key="index">{{dayWeek3}}</td>
        </tr>
        <tr>
            <td class="hilight-name-day">Чт.</td>
            <td  v-for="(dayWeek4, index) in data.standartHeaderGraphs.dayWeeks[3]"
                 :key="index">{{dayWeek4}}</td>
        </tr>
        <tr>
            <td class="hilight-name-day">Пт.</td>
            <td  v-for="(dayWeek5, index) in data.standartHeaderGraphs.dayWeeks[4]"
                 :key="index">{{dayWeek5}}</td>
        </tr>
        <tr>
            <td class="hilight-name-day">Сб.</td>
            <td  v-for="(dayWeek6, index) in data.standartHeaderGraphs.dayWeeks[5]"
                 :key="index">{{dayWeek6}}</td>
        </tr>
        <tr class="sunday-name-day">
            <td class="hilight-name-day">Вс.</td>
            <td  v-for="(dayWeek7, index) in data.standartHeaderGraphs.dayWeeks[6]"
                 :key="index">{{dayWeek7}}</td>
        </tr>
        <tr class="tr-number-week ">
            <td class="hilight-name-day td-number-week">Нед.</td>
            <td  v-for="(week, index) in data.standartHeaderGraphs.weekNumbers"
                 :key="index"
                 class="td-number-week"
            >{{week}}</td>
        </tr>
<!--        Данные таблицы-->
        <tr>
            <td class="hilight-name-day">Пн</td>
            <td  v-for="(dayWeek, index) in data.standartDataGraphs[0]"
                 :key="index"><span v-if="dayWeek === '*'" class="holiday">*</span><span v-else>{{dayWeek}}</span></td>
        </tr>
        <tr>
            <td class="hilight-name-day">Вт</td>
            <td  v-for="(dayWeek, index) in data.standartDataGraphs[1]"
                 :key="index"><span v-if="dayWeek === '*'" class="holiday">*</span><span v-else>{{dayWeek}}</span></td>
        </tr>
        <tr>
            <td class="hilight-name-day">Ср</td>
            <td  v-for="(dayWeek, index) in data.standartDataGraphs[2]"
                 :key="index"><span v-if="dayWeek === '*'" class="holiday">*</span><span v-else>{{dayWeek}}</span></td>
        </tr>
        <tr>
            <td class="hilight-name-day">Чт</td>
            <td  v-for="(dayWeek, index) in data.standartDataGraphs[3]"
                 :key="index"><span v-if="dayWeek === '*'" class="holiday">*</span><span v-else>{{dayWeek}}</span></td>
        </tr>
        <tr>
            <td class="hilight-name-day">Пт</td>
            <td  v-for="(dayWeek, index) in data.standartDataGraphs[4]"
                 :key="index"><span v-if="dayWeek === '*'" class="holiday">*</span><span v-else>{{dayWeek}}</span></td>
        </tr>
        <tr>
            <td class="hilight-name-day">Сб</td>
            <td  v-for="(dayWeek, index) in data.standartDataGraphs[5]"
                 :key="index"><span v-if="dayWeek === '*'" class="holiday">*</span><span v-else>{{dayWeek}}</span></td>
        </tr>
    </table>
    </div>
<!--    Пояснительная таблица обозначений -->
        <div class="text-center mb-3 mt-3"><h4>Обозначения</h4></div>
        <table class="table-graph">
            <tr>
                <td>К</td>
                <td>Каникулы</td>
                <td>Э</td>
                <td>Экзаменационная сессия</td>
                <td>У</td>
                <td>Учебная практика</td>
            </tr>
            <tr>
                <td>КТ</td>
                <td>Контрольная точка</td>
                <td>Г</td>
                <td>Государственные итоговая аттестация</td>
                <td>=</td>
                <td>Неделя отсутствует</td>
            </tr>
            <tr>
                <td>*</td>
                <td>Нерабочие праздничные дни</td>
                <td>П</td>
                <td>Производственная практика</td>
                <td> </td>
                <td>Теоретическое обучение</td>
            </tr>
        </table>
    </v-card>
</template>

<script>
import graphs from "@/helpers/api/graphs";
export default {
    name: "YearGraphTableAsPlany"
,
data(){
    return{
        radioTypeGraph: "asNormal",
        data: null
    }
},
mounted() {
    this.loadDataGraph()
},
computed: {
    groupID(){
        return this.$route.params.groupID
    },
    semester(){
        return this.$route.params.semester
    },
    standardHeaderGraph(){
        let year =  parseInt(this.data.currentYear.slice(0, 4));

        console.log("year", year);
        return this.data
    }
},
methods: {
    async loadDataGraph(){
        if (this.groupID){
            await graphs.getGraphCalendarGraphsTwoView(this.groupID).then((data)=>{
                //console.log("Data group", data.data)
                this.data = data.data
            })
        }
    }
},
}

</script>

<style scoped>
    .table-wrapper{
        width: 100%;
        display:block;
        overflow-x: auto;
    }
    .table-graph{
        border-collapse: collapse;
        width: 100%;
        border-radius: 5px;
        font-size: 0.8rem;
    }
    .table-graph td {
        padding: 3px;
        width: 1.7rem;
        height: 1.7rem;
        text-align: center;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .table-graph tr:not(:last-child) {
        /*border-bottom: thin solid rgba(0, 0, 0, 0.12);*/
    }

    /*Шапка номеров недель*/
    .table-graph .tr-first-number-of-week{
        border-bottom: none !important;
    }
    .table-graph .tr-last-number-of-week{
        border-bottom: none !important;
    }
    .table-graph .tr-number-week{
        border-bottom: none !important;
    }
    .table-graph .td-header-leftborder{
        border-left: thin solid rgba(0, 0, 0, 0.12);
    }
    .table-graph .td-first-number-of-week{
        border-bottom: none;
        cursor: help;
    }
    .table-graph .td-last-number-of-week{
        border-bottom: none;
        cursor: help;
    }
    .table-graph .td-number-week{
        border-bottom: none;
        background-color: #f6f6f6;
        cursor: help;
    }
    .table-graph .td-number-week:first-child{
        background-color: #f6f6f6;
        border-top-left-radius: 5px;
        /*border-bottom-left-radius: 5px;*/
    }
    .table-graph .td-number-week:last-child{
        background-color: #f6f6f6;
        border-top-right-radius: 5px;
        /*border-bottom-right-radius: 5px;*/
    }

    .cell-current-number-week{
        background-color: rgba(7, 7, 7, 0.15) !important;
        border-left: 1px solid rgba(7, 7, 7, 0.05) ;
        border-right: 1px solid rgba(7, 7, 7, 0.05) ;
    }
    /*Первая дисциплина в таблице графиков*/
    .table-graph .tr-discipline:first-child{
        border-top: thin solid rgba(7, 7, 7, 0.15);
        /*border-top: thin solid rgba(7, 7, 7, 0.12);*/
        /*box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;*/
    }
    .table-graph .tr-discipline-selected{
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
    }
    .table-graph tbody tr:nth-child(even){
        background-color: #f6f6f6;
    }
    .table-graph thead{
        font-size: 0.8rem;
        font-weight: bold;
        /*color: rgba(0, 0, 0, 0.6);*/
    }
    .table-graph tbody{
        font-size: 0.8rem;
    }

    .graph-cell{
        border-left: thin solid rgba(7, 7, 7, 0.15);
    }
    .graph-cell-control-point{
        cursor: help;
    }

    /* Вторая таблица (стандартная) */
    .hilight-name-day{
        font-weight: bold;
        font-size: 0.8rem;
    }
    .sunday-name-day, .holiday{
        color: #ff0000;
    }
</style>