<template>
  <div>
    <span class="mr-2">
      Учебный год:
    </span>
    <v-menu class="ml-2" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="on" small>
          <span>
            {{value}}
          </span>
          <v-icon right>mdi-chevron-down</v-icon>
        </v-chip>
      </template>
      <v-list>
        <v-list-item
                @click="$emit('input', item)"
                v-for="(item, index) in items"
                :key="index"
                :disabled="value === item"
        >
          <v-list-item-title>
            {{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import common from "../helpers/common";
export default {
  name: "YearPicker",
  props: {
    value: {
      type: String,
      default: common.getCurrentYear()
    }
  },
  data () {
    return {
      items: common.getYearsList(2015).sort((a,b) => a > b ? -1 : 1)
    }
  },
}
</script>

<style scoped>

</style>
